<google-map *ngIf="!lazyLoad" [height]="height" [width]="width" [center]="center" [zoom]="zoom"
    (mapClick)="mapClick($event)" (mapDrag)="mapDrag($event)" (mapInitialized)="mapInitialize($event)"
    (tilesloaded)="tilesload($event)" [options]="options">
    <map-marker
        *ngIf="marker && marker.position && marker.position.hasOwnProperty('lat') && marker.position.hasOwnProperty('lng')"
        #markerElem="mapMarker" [position]="marker.position" [label]="marker.label" [matTooltip]="marker.title"
        [options]="marker.options" (mapClick)="markerClick($event, marker, markerElem)" [icon]="marker.icon">
    </map-marker>
    <ng-container *ngFor="let mark of markers">
        <map-marker #markersElem="mapMarker"
            *ngIf="mark && mark.position && mark.position.hasOwnProperty('lat') && mark.position.hasOwnProperty('lng')"
            [position]="mark.position" [label]="mark.label" [matTooltip]="mark.title" [icon]="mark.icon"
            [iconSize]="{width: 10, height: 10}" [options]="mark.options"
            (mapClick)="markerClick($event, mark, markersElem)" (mapMouseover)="markerHover($event, mark, markersElem)"
            (mapMouseout)="markerHoverOut($event)">
        </map-marker>
    </ng-container>
    <!-- <ng-container *ngFor="let mark of usersMarkers">
        <map-marker #markersElem="mapMarker"
            *ngIf="mark && mark.position && mark.position.hasOwnProperty('lat') && mark.position.hasOwnProperty('lng')"
            [position]="mark.position" [label]="mark.label" [matTooltip]="mark.title" [icon]="mark.icon"
            [iconSize]="{width: 10, height: 10}" [options]="mark.options"
            (mapClick)="markerClick($event, mark, markersElem)" (mapMouseover)="markerHover($event, mark, markersElem)"
            (mapMouseout)="markerHoverOut($event)">
        </map-marker>
    </ng-container> -->
    <map-info-window>
        <div style="text-align: center;">
            <ng-container *ngIf="selectedMarker">
                <a *ngIf="selectedMarker.markerType === 'camera'"
                    style="max-height: 100px;margin-top: 5px;cursor:pointer;"
                    (click)="selectMarkerInfo()">{{selectedMarker.camera_id}}<br>
                    <img style="max-height: 80px;max-width: 100px;" src="{{selectedMarker.pictureLink}}">
                </a>
                <a *ngIf="selectedMarker.markerType === 'investigator'"
                    style="max-height: 100px;margin-top: 5px;cursor:pointer;" (click)="selectMarkerInfo()">
                    <div>{{selectedMarker.name}}</div>
                    <!-- <img style="max-height: 80px;max-width: 100px;" src="{{selectedMarker.pictureLink}}"><br> -->
                    <div>{{selectedMarker.rank}}</div>
                    <div *ngIf="selectedMarker.years_in_service && selectedMarker.years_in_service > 0">
                        {{selectedMarker.years_in_service}} {{'Years' | translate}}</div>
                </a>
                <a *ngIf="selectedMarker.markerType === 'investigator-group'"
                    style="max-height: 100px;margin-top: 5px;cursor:pointer;" (click)="selectMarkerInfo()">
                    <div>{{selectedMarker.name}}</div>
                    <!-- <img style="max-height: 80px;max-width: 100px;" src="{{selectedMarker.picture}}"> -->
                </a>
                <a *ngIf="selectedMarker.markerType === 'case'" style="max-height: 40px;margin-top: 5px;cursor:pointer;"
                    (click)="selectMarkerInfo()">{{selectedMarker.case_id}}<br>
                    {{selectedMarker.dataType}}<br>
                    {{selectedMarker.description | truncate: ['100','...']}}
                </a>
                <span *ngIf="selectedMarker.markerType === 'tracking'"
                    style="max-height: 40px;margin-top: 5px;cursor:pointer;">
                    {{selectedMarker.createdAt | date: 'yy-MM-dd HH:mm'}}<br>
                    {{selectedMarker.camera_id}}
                </span>
                <span *ngIf="selectedMarker.markerType === 'annotation'"
                    style="max-height: 110px;margin-top: 5px;cursor:pointer;">
                    <img style="max-height: 80px;max-width: 100px;" src="{{selectedMarker.pictureLink}}">
                </span>
                <div *ngIf="selectedMarker.markerType === 'note'" style="max-height: 110px;cursor:pointer;">
                    <div *ngIf="selectedMarker.note.person_of_interest || selectedMarker.note.car_of_interest">
                        {{selectedMarker.note.action_date || selectedMarker.note.createdAt | date: 'yy-MM-dd HH:mm'}}
                    </div>
                    <span>{{selectedMarker.description | truncate: ['100','...']}}</span>
                </div>
                <div *ngIf="selectedMarker.markerType === 'document'" style="max-height: 110px;cursor:pointer;">
                    <span>{{selectedMarker.description | truncate: ['100','...']}}</span>
                </div>
            </ng-container>
        </div>
    </map-info-window>
</google-map>