import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';

@Injectable()
export class MessagesStatusService {
    constructor() {
      this.clearOldActivities();
      this.getUserActivity();
    }
    private clearOldActivities(){
      let keyList = [];
      for(var i =0; i < localStorage.length; i++){
        if(localStorage.key(i).indexOf('-userMessageActivity') > -1){
          let activity = JSON.parse(localStorage.getItem(localStorage.key(i)));
          if(activity.hasOwnProperty('initiated')){
            let currentDate: Moment = moment.utc();
            let logTime: Moment = moment.utc(activity['initiated']).local();
            if(currentDate.diff(logTime, 'years') > 1){
              keyList.push(localStorage.key(i));
            }
          }else{
            keyList.push(localStorage.key(i));
          }
        }
      }
      for(let ky of keyList){
        localStorage.removeItem(ky);
      }
    }
    public getStatus(key): any{
      let userMessageActivity = this.getUserActivity();
      if (userMessageActivity.hasOwnProperty('logs') && userMessageActivity['logs'].hasOwnProperty(key))
        return userMessageActivity['logs'][key];
      else{
        return false;
      }
    }
    private getUserActivity(){
      let userMessageActivity = {};
      if (localStorage.getItem('currentUser')){
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser.hasOwnProperty('_id')){
          if (localStorage.getItem( currentUser._id + '-userMessageActivity')){
            userMessageActivity = JSON.parse(localStorage.getItem( currentUser._id + '-userMessageActivity') || '');
          }else{
            userMessageActivity = {initiated: moment.utc(), _id: currentUser._id, logs: {}};
            localStorage.setItem(currentUser._id + '-userMessageActivity',  JSON.stringify(userMessageActivity));
          }
        }
      }
      return userMessageActivity;
    }
    public setStatus(key: string, status: boolean = false){
      let userMessageActivity = this.getUserActivity();
      if (userMessageActivity.hasOwnProperty('_id') && userMessageActivity.hasOwnProperty('logs')){
        userMessageActivity['logs'][key] = status;
        localStorage.setItem( userMessageActivity['_id'] + '-userMessageActivity',  JSON.stringify(userMessageActivity));
      }
    }
}
