import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";

export interface ChatData {
    show: boolean;
    caseId: string;
    caseName: string;
    investigators: any;
    investigatorGroups: any;
    isInvestigatorProfile?: boolean;
}

export interface CallData {
    show: boolean;
    caseId?: string;
    investigators?: any;
    selectedUsers?: any;
    invitedBy?: string;
    callerId?: string;
    investigatorGroups?: any;
    isInvestigatorProfile?: boolean;
}

@Injectable()
export class ChatService {

    public selectedUser: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public selectedChatUser: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public showChatDialogSubject: BehaviorSubject<ChatData> = new BehaviorSubject<ChatData>({
        show: undefined,
        caseId: '',
        caseName: '',
        investigatorGroups: [],
        investigators: [],
    });
    public showCallDialogSubject: BehaviorSubject<CallData> = new BehaviorSubject<CallData>({
        show: false
    });
    public ongoingCall: any = undefined;

    constructor(private router: Router) { }

    showChatDialog(senderId, senderName, shouldClear: boolean = false) {
        this.router.navigate(([`/notifications`])).then(() => {
            this.selectedUser.next({
                isGroup: false,
                id: senderId,
                name: senderName,
                shouldClear: shouldClear,
            });

            // let tokens = [];
            // userTokens.deviceids?.map(j => tokens.push(j.deviceId));

            this.showChatDialogSubject.next({
                show: true,
                investigators: [{ _id: senderId, name: senderName }],
                caseId: undefined,
                caseName: undefined,
                investigatorGroups: [],
                isInvestigatorProfile: true,
            });
        });
    }
}