import { Injectable, NgZone } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LoaderService } from "./loader.service";
import { RequestService } from "./request.service";
import { LayoutUtilsService } from "./utils/layout-utils.service";
import { Router } from '@angular/router';
import { BehaviorSubject } from "rxjs";
import { ViewInvestigatorDialogComponent } from "src/app/pages/case-page/investigator/view-investigator-dialog/view-investigator-dialog.component";
import { MatDialog } from "@angular/material/dialog";

export enum TypesEnum {
    VIDEO = 'Video',
    AUDIO = 'Audio',
    IMAGE = 'Image',
    FILE = 'File'
};

@Injectable()
export class CaseService {

    public setNewCaseSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public caseMetadata: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public profile: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public noteMetadata: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public userMetadata: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public investigatorGroups: any = [];
    public investigators: any = [];
    public investigatorsAll: any = [];
    public investigatorsGroup: any = [];
    public caseLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public caseUpdated: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

    constructor(private requestService: RequestService, private zone: NgZone, private layoutUtilsService: LayoutUtilsService, private translate: TranslateService, private loadService: LoaderService, private router: Router, private dialog: MatDialog) { }

    getCaseMetadata(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.getMetadataPromise('case').then((metadata: any) => {
                this.caseMetadata.next(metadata.results || []);
                resolve(metadata.results);
            }).catch((e) => reject(e));
        });
    }

    getNoteMetadata(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.getMetadataPromise('note').then((metadata: any) => {
                this.noteMetadata.next(metadata.results || []);
                resolve(metadata.results || []);
            }).catch((e) => reject(e));
        });
    }

    getUserMetadata(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.getMetadataPromise('user').then((metadata: any) => {
                this.userMetadata.next(metadata.results || []);
                resolve(metadata.results || []);
            }).catch((e) => reject(e));
        });
    }

    getCase(caseId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getSingleData('case', caseId, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    this.profile.next(data.results);
                    resolve(data.results);
                }
            });
        });
    }

    private getMetadataPromise(type: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getMetaData(type, undefined, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    resolve(data);
                }
            });
        });
    }

    getDocuments(pageIndex = 0, pageSize = 10, caseId, searchValue, type): Promise<void> {
        return new Promise((resolve, reject) => {
            let andFilter: any = [
                { "organizationId._id": { "$in": [this.requestService.orgId] } },
                { "case._id": { "$eq": caseId } },
            ];

            if (type) {
                andFilter.push({ "type": { "$eq": type } });
            }

            this.requestService.postRequest('doc', 'search', {
                page: pageIndex + 1,
                count: pageSize,
                order: [{ "field": "createdAt", "order": "desc" }],
                fields: ["createdAt", "name", "case", "type", "url", "createdName"],
                termfieldstermfields: ['name'],
                term: searchValue,
                filter: {
                    "$and": andFilter
                }
            }, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    resolve(data);
                }
            });
        });
    }

    getInvestigatorGroups(caseId): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord('', `case/investigatorgroup/${caseId}/list`, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    data.results = data.results.map((group, index) => ({
                        ...group,
                        investigatorNames: group.investigators.map(inv => (inv.alternative_name || inv.name)).join(', '),
                        investigatorIds: group.investigators.map(inv => (inv._id)),
                        shortId: (index + 1) < 10 ? '0' + (index + 1) : (index + 1)
                    }));

                    // this.investigatorgroups.map((i, index) => {
                    //     i.shortId = (index + 1) < 10 ? '0' + (index + 1) : (index + 1);
                    // });
                    this.investigatorGroups = data.results;
                    resolve(data.results);
                }
            });
        });
    }

    getInvestigatorsList(caseId): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord('', `case/investigators/${caseId}/list`, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    // this.investigators = data.results;
                    resolve(data);
                }
            });
        });
    }
    getInvestigators(caseId): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord('', `case/user/${caseId}/all`, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    this.investigators = data.results;
                    resolve(data);
                }
            });
        });
    }
    getInvestigatorsGroup(caseId): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord('', `case/investigatorgroup/${caseId}/list`, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    this.investigatorsGroup = data.results;
                    resolve(data);
                }
            });
        });
    }

    getCameras(caseId): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord('', `case/camera/${caseId}/all`, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    resolve(data);
                }
            });
        });
    }

    getTimelinePromise(caseId: string, pageIndex: number, pageSize: number, filters?: any): Promise<void> {
        return new Promise((resolve, reject) => {
            let andFilter: any = [
                { "organizationId._id": { "$in": [this.requestService.orgId] } },
                { "case._id": { "$eq": caseId } }
            ];

            let documentFilter = {};

            if (filters) {
                if (filters.investigators?.length && !filters.investigators.includes("L1")) {
                    andFilter.push({
                        "$or": [{ "investigators._id": { "$in": filters.investigators } }, { "intiatedFrom._id": { "$in": filters.investigators } }]
                    });
                }
                if (filters.fileType?.length) {
                    documentFilter = {
                        "document.type": { "$in": filters.fileType }
                    }
                }
            }

            this.requestService.postRequest('note', 'search', {
                page: pageIndex + 1,
                count: pageSize,
                "order": [{ "field": "updatedAt", "order": "desc" }], "fields": ["createdAt", "createdBy", "name", "camera", "case_status", "case", "case_type", "type", "notes", "createdName", "document", "location", "updatedAt", "geolocation", "action_date", "plate_of_interest", "person_of_interest_name", "person_of_interest_description", "car_of_interest", "person_of_interest", "investigators", "investigatorgroup", "car_of_interest_description", "car_of_interest_identifier", "car_color", "initiatedFrom", "car_model", "car_make", "car_type", "person_of_interest_gender"], "term": "",
                "filter": {
                    "$and": andFilter
                },
                document: documentFilter,
            }, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    resolve(data);
                }
            });
        });
    }

    getUserList(users: any, groups: any) {
        let userNames = [];
        users?.forEach(user => {
            userNames.push(user.alternative_name || user.name);
        });
        groups?.forEach(group => {
            userNames.push(group.name);
        });
        let names = userNames.join(", ");
        if (userNames.length > 1) {
            names = names.replace(new RegExp(',' + "(?=[^" + ',' + "]*$)"), ' and ');
        }
        return names;
    }

    viewProfile(userId: string, userMetadata: any) {
        this.loadService.display(true);
        this.requestService.getRecord(userId, 'user', (data, error) => {
            if (data) {
                this.loadService.display(false);
                const dialog = this.dialog.open(ViewInvestigatorDialogComponent, {
                    data: {
                        name: data.results.alternative_name,
                        badgeNo: data.results.badge_no,
                        rank: data.results.rank,
                        division: data.results.division,
                        pictureLink: data.results.pictureLink,
                        userMetadata: userMetadata,
                        _id: data.results._id,
                        role: data.results.resources[0]
                    },
                    disableClose: false,
                    width: 'fit-content',
                })
            }
            if (error) {
                this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
                this.loadService.display(false);
            }
        });
    }

    getCarPlates(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord('list', 'note/carplate', (data, error) => {
                if (data) {
                    resolve(data.results);
                }
                else if (error) {
                    reject(error);
                }
            });
        });
    }

    getPeople(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.requestService.getRecord('list', 'note/persons', (data, error) => {
                if (data) {
                    resolve(data.results);
                }
                else if (error) {
                    reject(error);
                }
            });
        });
    }

    getAssignedCasesPromise(userId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            let andFilter: any = [{ "organizationId._id": { "$in": [this.requestService.orgId] } }];

            andFilter.push({ "investigators._id": { "$in": [userId] } });

            this.requestService.postRequest('case/search', '', {
                order: [{ "field": "name", "order": "asc" }],
                fields: ["case_id", "createdAt"],
                term: '',
                filter: {
                    "$and": andFilter
                }
            }, (data, error) => {
                if (error) {
                    reject(error);
                }
                if (data) {
                    resolve(data.results);
                }
            });
        });
    }
}
