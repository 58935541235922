import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs/internal/Subscription";
import { RequestService } from "src/app/shared";
import { environment } from "src/environments/environment";

interface Profile {
    _id: string;
    name: string;
    badgeNo: string;
    rank: string;
    division: string;
    pictureLink: string;
    userMetadata: any;
    role: any;
}

@Component({
    selector: 'app-view-investigator-dialog',
    templateUrl: './view-investigator-dialog.component.html',
    styleUrls: ['./view-investigator-dialog.component.scss'],
})

export class ViewInvestigatorDialogComponent implements OnInit, OnDestroy {

    protected subscriptions: Subscription[] = [];
    public rankEnum: any = [];
    public divisionEnum: any = [];
    public badgeNoMeta = undefined;
    public rankMeta = undefined;
    public divisionMeta = undefined;
    public isInvestigator: boolean = false;
    public rolesCannotView: string[] = [environment.customKeys.roleAdmin, environment.customKeys.roleRegionCommanders, environment.customKeys.rolePrefectureCommanders];

    constructor(private requestService: RequestService, public dialogRef: MatDialogRef<ViewInvestigatorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Profile, private router: Router) {
    }

    ngOnInit(): void {
        this.rankEnum = this.data.userMetadata.fields.find(i => i.name == 'rank').enum;
        this.divisionEnum = this.data.userMetadata.fields.find(i => i.name == 'division').enum;
        this.badgeNoMeta = this.data.userMetadata.fields.find(i => i.name == 'badge_no');
        this.rankMeta = this.data.userMetadata.fields.find(i => i.name == 'rank');
        this.divisionMeta = this.data.userMetadata.fields.find(i => i.name == 'division');
        this.subscriptions.push(
            this.requestService.currentUserSubject.subscribe((data) => {
                if (data) {
                    this.isInvestigator = this.requestService.isUserRoleInvestigator();
                }
            })
        );
    }

    ngOnDestroy(): void {

    }

    viewProfile() {
        if (this.data._id) {
            this.dialogRef.close();
            window.open(`/investigator/${this.data._id}`, "_blank")
            // this.router.navigate([`/investigator/${this.data._id}`]);
        }
    }
}